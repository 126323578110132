/* small screens */
.bio {
  align-items: center;
  display: flex;
  flex-direction: column;
}


.bio__section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3rem;
}

.container {
  max-width: 1000px;
  margin-top: 2rem;
}


.pic {
  width: 325px;
}

.footer {
  padding: 0 1rem 3rem 1rem;
}

.whatIsWTB {
  text-align: left;
  padding: 0 2rem;
  margin-bottom: 3rem;
  max-width: 1100px;
}

.aboutWTB {
  margin-top: 2rem;
}

/* large screens */
@media (min-width: 768px) {
  .aboutWTB {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .footer {
    padding-bottom: 3rem;
  }
  .pic {
    margin-right: 70px;
  }
  .twitter {
    margin-right: 70px;
  }
}
