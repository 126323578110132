/* small screens */
.qa {
  flex-basis: 85%;
  margin-top: 2rem;
  text-align: left;
}

.qa__q {
  color: #aaa;
  font-weight: 600;
}

.name {
  font-size: 3.25rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  text-align: center;
}

/* large screens */
@media (min-width: 768px) {
  .qa {
    flex-basis: 60%;
    margin-top: 0;
  }

  .name {
    text-align: left;
  }
}