@import 'src/styles/colors';

/* small screens */
.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}

.nav-link__button {
  align-items: center;
  border-radius: 35px;
  color: rgb(83, 83, 83);
  font-weight: 500;
  display: flex;
  height: 3rem;
  justify-content: center;
  width: 10rem;
  margin-bottom: 1rem;
}

/* large screens */
@media (min-width: 768px) {
  .nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    margin: 5rem auto;
    max-width: 900px;
  }
}


.nav-link__button:hover {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 2px 2px 4px 0 rgba(0, 0, 0, 0.1);;
}

.nav-link {
  text-decoration: none;
}

.nav-link__button--green {
  background-color: $green;
}

.nav-link__button--purple {
  background-color: $purple;
}

.nav-link__button--red {
  background-color: $red;
}

.nav-link__button--blue {
  background-color: $blue;
}

.nav-link__button--yellow {
  background-color: $orange;
}

.nav-link--active {
  font-weight: 700;
}

.nav-link--active .nav-link__button {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 2px 2px 4px 0 rgba(0, 0, 0, 0.1);;
  color: white;
}

.coffeeLogo {
  height: 15px;
  margin-right: 5px;
}

.supportLink {
  cursor: pointer;
}
