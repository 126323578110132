/* small screens */
.subheader {
  padding: 1rem;
}

/* large screens */
@media (min-width: 768px) {
  .subheader {
    padding: 0;
  }
}