body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  line-height: 1.8;
  text-align: center;
}

h1 {
  color: rgb(83, 83, 83);
  font-size: 3rem;
  padding: 20px;
  margin: 3rem 0 1rem 0;
}
@media (min-width: 768px) {
  h1 {
    font-size: 5em;
    margin: 3rem 0;
  }
}

h2 {
  color: rgb(83, 83, 83);
  font-size: 1.25rem;
  font-weight: 400;
}

a {
  color: rgb(83, 83, 83);
  text-decoration: none;
}
