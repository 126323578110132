body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* small screens */
.nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 1rem 0; }

.nav-link__button {
  -webkit-align-items: center;
          align-items: center;
  border-radius: 35px;
  color: #535353;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  height: 3rem;
  -webkit-justify-content: center;
          justify-content: center;
  width: 10rem;
  margin-bottom: 1rem; }

/* large screens */
@media (min-width: 768px) {
  .nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin: 5rem auto;
    max-width: 900px; } }

.nav-link__button:hover {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 2px 2px 4px 0 rgba(0, 0, 0, 0.1); }

.nav-link {
  text-decoration: none; }

.nav-link__button--green {
  background-color: #bae0b0; }

.nav-link__button--purple {
  background-color: #8c7cc8; }

.nav-link__button--red {
  background-color: #fda1a4; }

.nav-link__button--blue {
  background-color: #9bc6c8; }

.nav-link__button--yellow {
  background-color: #fec37f; }

.nav-link--active {
  font-weight: 700; }

.nav-link--active .nav-link__button {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  color: white; }

.coffeeLogo {
  height: 15px;
  margin-right: 5px; }

.supportLink {
  cursor: pointer; }

.header {
  line-height: 1.3; }

/* small screens */
.subheader {
  padding: 1rem; }

/* large screens */
@media (min-width: 768px) {
  .subheader {
    padding: 0; } }

.audio-player {
  margin-bottom: 2rem;
  width: 240px; }

.episode {
  border-radius: 35px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  line-height: 30px;
  margin: 3rem auto;
  max-width: 215px;
  padding: 3rem; }

@media (min-width: 768px) {
  .logo {
    margin-top: -35px; }
  .episode {
    max-width: 675px; }
  .audio-player {
    width: 400px; } }

.episode__info {
  margin-bottom: 2rem;
  text-align: center; }

.episode__description {
  text-align: left; }

.episode__description p:first-of-type {
  margin-top: 0; }

.episode:nth-last-child(5n-7) {
  background-color: #bae0b0; }

.episode:nth-last-child(5n-6) {
  background-color: #fec37f; }

.episode:nth-last-child(5n-5) {
  background-color: #9bc6c8; }

.episode:nth-last-child(5n-4) {
  background-color: #fda1a4; }

.episode:nth-last-child(5n-3) {
  background-color: #8c7cc8; }

.episode__title {
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center; }


/* small screens */
.qa {
  -webkit-flex-basis: 85%;
          flex-basis: 85%;
  margin-top: 2rem;
  text-align: left; }

.qa__q {
  color: #aaa;
  font-weight: 600; }

.name {
  font-size: 3.25rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  text-align: center; }

/* large screens */
@media (min-width: 768px) {
  .qa {
    -webkit-flex-basis: 60%;
            flex-basis: 60%;
    margin-top: 0; }
  .name {
    text-align: left; } }

/* small screens */
.bio {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.bio__section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 3rem; }

.container {
  max-width: 1000px;
  margin-top: 2rem; }

.pic {
  width: 325px; }

.footer {
  padding: 0 1rem 3rem 1rem; }

.whatIsWTB {
  text-align: left;
  padding: 0 2rem;
  margin-bottom: 3rem;
  max-width: 1100px; }

.aboutWTB {
  margin-top: 2rem; }

/* large screens */
@media (min-width: 768px) {
  .aboutWTB {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .footer {
    padding-bottom: 3rem; }
  .pic {
    margin-right: 70px; }
  .twitter {
    margin-right: 70px; } }

/* small screens */
.topic-link {
  text-decoration: underline; }

.topic-link:visited {
  color: #535353; }

.topics {
  margin-left: 18%;
  margin-bottom: 3rem;
  text-align: left;
  width: 240px; }

.topics__item {
  margin: 1rem; }

.order {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 125px;
  margin-top: 2rem; }

.order-view {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 35px; }

.order-view-link {
  margin-right: 10px;
  padding: 10px;
  border-radius: 35px;
  cursor: pointer; }

.order-view__button--selected {
  background-color: #fda1a4; }

.alphabet-search {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 2rem 1rem; }

.chronological-search {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 20px; }

.chrono-months {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center; }

.alphabet-search__link {
  color: #535353;
  margin-right: 1rem;
  font-weight: 600;
  text-decoration: none; }

.alphabet-search__link:hover {
  text-decoration: underline; }

.sort {
  background-color: #fda1a4;
  font-size: 32px;
  margin: 3rem 0;
  padding: 1rem 0; }

.order__button {
  cursor: pointer;
  color: #535353;
  font-size: 50px; }

.order__button:hover {
  color: #8c7cc8; }

.order__button--selected {
  color: #9bc6c8; }

/* large screens */
@media (min-width: 1105px) {
  .alphabet-search {
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 534px;
    width: 130px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
  .sort {
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
    position: fixed;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 240px;
    bottom: -47px; }
  .topics {
    margin-left: 45%; } }

.links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  font-size: 40px;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: auto; }

.contact-link {
  border-radius: 8px;
  padding: 1rem;
  text-decoration: none; }

.contact-icon {
  margin-right: 0.75rem; }

/* large screens */
@media (min-width: 768px) {
  .links {
    -webkit-flex-direction: row;
            flex-direction: row;
    max-width: 932px; } }

.icon--green {
  color: #bae0b0; }

.icon--purple {
  color: #8c7cc8; }

.icon--red {
  color: #fda1a4; }

.icon--blue {
  color: #9bc6c8; }

.icon--yellow {
  color: #fec37f; }

.hompepage-logo {
  margin-top: 0; }

.episode-icon {
  margin-right: 0.5rem; }

.listen-link-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 20px;
  padding: 4px; }

/* large screens */
@media (min-width: 768px) {
  .homepage-logo {
    margin-top: -35px; }
  .listen-link-container {
    display: block; } }

.listen-link {
  margin-right: 1rem;
  text-decoration: none; }

.episode-icon {
  margin-right: 0.5rem; }

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  line-height: 1.8;
  text-align: center; }

h1 {
  color: #535353;
  font-size: 3rem;
  padding: 20px;
  margin: 3rem 0 1rem 0; }

@media (min-width: 768px) {
  h1 {
    font-size: 5em;
    margin: 3rem 0; } }

h2 {
  color: #535353;
  font-size: 1.25rem;
  font-weight: 400; }

a {
  color: #535353;
  text-decoration: none; }

