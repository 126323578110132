@import 'src/styles/colors';

/* small screens */
.topic-link {
  text-decoration: underline;
}

.topic-link:visited {
  color: rgb(83, 83, 83);
}

.topics {
  margin-left: 18%;
  margin-bottom: 3rem;
  text-align: left;
  width: 240px;
}

.topics__item {
  margin: 1rem;
}

.order {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 125px;
  margin-top: 2rem;
}

.order-view {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
}

.order-view-link {
  margin-right: 10px;
  padding: 10px;
  border-radius: 35px;
  cursor: pointer;
}

.order-view__button--selected {
  background-color: $red;
}

.alphabet-search {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem 1rem;
}

.chronological-search {
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.chrono-months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}

.alphabet-search__link {
  color: rgb(83, 83, 83);
  margin-right: 1rem;
  font-weight: 600;
  text-decoration: none;
}

.alphabet-search__link:hover {
  text-decoration: underline;
}

.sort{
  background-color: $red;
  font-size: 32px;
  margin: 3rem 0;
  padding: 1rem 0;
}

.order__button {
  cursor: pointer;
  color: rgb(83, 83, 83);
  font-size: 50px;
}

.order__button:hover {
  color: $purple;
}

.order__button--selected {
  color: $blue;
}

/* large screens */
@media (min-width: 1105px) {
  .alphabet-search {
    flex-direction: column;
    height: 534px;
    width: 130px;
    justify-content: flex-start;
  }

  .sort {
    height: 100%;
    align-items: center;
    flex-direction: column;
    display: flex;
    position: fixed;
    justify-content: space-around;
    width: 240px;
    bottom: -47px;
  }

  .topics {
    margin-left: 45%;
  }

  .chronological-search {

  }
}
