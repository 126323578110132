@import 'src/styles/colors';

.links {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 40px;
  justify-content: space-around;
  margin: auto;
}

.contact-link {
  border-radius: 8px;
  padding: 1rem;
  text-decoration: none;
}

.contact-icon {
  margin-right: 0.75rem;
}

/* large screens */
@media (min-width: 768px) {
  .links {
    flex-direction: row;
    max-width: 932px;
  }
}

.icon--green {
  color: $green;
}

.icon--purple {
  color: $purple;
}

.icon--red {
  color: $red;
}

.icon--blue {
  color: $blue;
}

.icon--yellow {
  color: $orange;
}
