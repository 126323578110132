@import 'src/styles/colors';

.audio-player {
  margin-bottom: 2rem;
  width: 240px;
}

.episode {
  border-radius: 35px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  line-height: 30px;
  margin: 3rem auto;
  max-width: 215px;
  padding: 3rem;
}

@media (min-width: 768px) {
  .logo {
    margin-top: -35px;
  }
  .episode {
    max-width: 675px;
  }

  .audio-player {
    width: 400px;
  }
}

.episode__info {
  margin-bottom: 2rem;
  text-align: center;
}

.episode__description {
  text-align: left;
}

.episode__description p:first-of-type {
  margin-top: 0;
}

.episode:nth-last-child(5n-7) {
  background-color: $green;
}

.episode:nth-last-child(5n-6) {
  background-color: $orange;
}

.episode:nth-last-child(5n-5) {
  background-color: $blue;
}

.episode:nth-last-child(5n-4) {
  background-color: $red;
}

.episode:nth-last-child(5n-3) {
  background-color: $purple;
}

.episode__title {
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
}
